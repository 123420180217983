<template>
  <div>
    <common-header/>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <h3>1、基本信息</h3>
      <a-form-model-item label="企业社会统一征信代码">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="印章类型">
        <a-checkbox-group v-model="form.type">
          <template v-for="(item, index) in typeArr">
          <a-checkbox :value="item.id" name="type" :key="index">
            {{item.name}}
          </a-checkbox>
          </template>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="印章属性">
        <a-radio-group v-model="form.resource">
          <a-radio value="1">
            企业印章
          </a-radio>
          <a-radio value="2">
            政务印章
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <h3>2、企业信息</h3>
      <company-info/>
      <h3>3、资料上传</h3>
      <!-- <data-upload/> -->
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          Create
        </a-button>
        <a-button style="margin-left: 10px;">
          Cancel
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <common-footer/>
  </div>
</template>

<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import CompanyInfo from './components/CompanyInfo.vue';
// import DataUpload from './components/DataUpload.vue';

export default {
  components: {
    CommonHeader,
    CommonFooter,
    CompanyInfo,
    // DataUpload,
  },
  data() {
    return {
      typeArr: [
        { id: 1, name: '法定名称章' },
        { id: 2, name: '财务专用章' },
        { id: 3, name: '发票专用章' },
        { id: 4, name: '合同专用章' },
        { id: 5, name: '法定代表人名章' },
        { id: 6, name: '其他类型章' }],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
    };
  },
  methods: {
    onSubmit() {
      console.log('submit!', this.form);
    },
  },
};
</script>

<style lang="less" scoped>
.ant-form {
  padding-top: 120px;
}
</style>
