<template>
  <div class="common-footer">
    <div class="footer-info">
      <!-- <div class="common-detail" v-if="!isIndex">
        <div class="focus-us">
          <p @click="toPage('/LocalizationPlan')">国产化方案</p>
          <p @click="toPage('https://eseal.lngsyz.com/#/',true)">电子印章</p>
          <p @click="toPage('/About')">关于广烁</p>
        </div>
        <div class="company-product">
          <p @click="toPage('https://kq.lngsyz.com/login',true)">刻企服务</p>
          <p>产品中心</p>
        </div>
        <div class="resources-download">
          <p>申请有礼</p>
          <p>资源下载</p>
        </div>
      </div> -->
      <div class="common-detail">
        <div class="focus-us">
          <p @click="toPage('/')">首页</p>
          <p @click="toPage('/LocalizationPlan')">国产化方案</p>
          <p @click="toPage('http://seal.lngsyz.com', true)">电子印章</p>
        </div>
        <div class="company-product">
          <p @click="toPage('https://kq.lngsyz.com/login',true)">刻企服务</p>
          <p @click="toPage('/Product')">印材设备</p>
<!--          <p @click="toPage('https://kq.lngsyz.com/collect/sealInfo/toMakeSeal',true)">申请有礼</p>-->
        </div>
        <div class="resources-download">

          <p @click="toPage('/ResourcesDownload')">资源下载</p>
          <p @click="toPage('/About')">关于广烁</p>
          <p @click="toPage('/ContactUs')">联系我们</p>
        </div>
      </div>
      <div class="code-info">
        <img src="../assets/imgs/code@2x.png" class="gs-code">
        <div>关注广烁微信</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonFooter',
  props: {
    isIndex: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toPage(url, isExternal) {
      if (isExternal) {
        window.open(url, '_black');
      } else if (url && url !== this.$route.path) {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="less" scoped>
  .common-footer {
    height: 315px;
    display: flex;
    background-color: #1C1F37;
    font-family: PingFangSC-Semibold, PingFang SC;
    p {
      margin: 0;
      cursor: pointer;
    }
    .footer-info {
      display: flex;
      margin: 0 auto;
    }
    .common-detail {
      display: flex;
      padding-top: 111px;
    }
    .focus-us {
      margin-right: 60px;
      color: #fff;
      div {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      p {
        margin-top: 10px;
      }
      p:first-of-type {
        margin-top: 0;
      }
    }
    .company-product {
      margin-right: 60px;
      color: #fff;
      div {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      p {
        margin-top: 10px;
      }
      p:first-of-type {
        margin-top: 0;
      }
    }
    .resources-download {
      color: #fff;
      div {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      p {
        margin-top: 10px;
      }
      p:first-of-type {
        margin-top: 0;
      }
    }
    .code-info {
      margin-left: 537px;
      .gs-code {
        height: 120px;
        width: 120px;
        margin-top: 88px;
      }
      div {
        margin-top: 10px;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
      }
    }
  }
</style>
