<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="企业名称">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="企业社会统一征信代码">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="单位电话">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="法人姓名">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="法人联系电话">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="法人证件号码">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="地址">
        <a-input v-model="form.name" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'CompanyInfo',
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        name: '',
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
    };
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.ant-form {
  padding-top: 120px;
}
</style>
