<template>
  <div class="common-header">
    <div class="logo-info">
      <img src="../assets/imgs/logo@2x.png" v-if="clientWidth>mobileWidth" @click="toIndex">
      <img src="../assets/imgs/mobile/logo@2x.png" v-else @click="toIndex"/>
      <div class="menu-wapper" v-if="isShowMenu && clientWidth>mobileWidth">
        <span
            @click.stop="handleClickMenu(item)"
            :class="['menu-item',item.key === currentMenu?'menu-item-active':'']"
            v-for="item in menus" :key="item.key">
          {{ item.title }}
          <div class="second-menu" v-if="item.key === 'product' && item.showSubMenu">
            <p @click="downLoad(1)">广烁电子印章客户端</p>
            <p @click="downLoad(4)">广烁电子印章签章客户端V2.0（使用说明书）</p>
            <p @click="downLoad(2)">广烁电子印章中间件</p>
<!--            <p @click="downLoad(3)">广烁印章排版系统</p>-->
            <p class="cailiao" @click.stop="toPage('/Product/SealMaterial')">印章材料</p>
            <p @click.stop="toPage('/Product/SealingEquipment')">印章设备</p>
          </div>

          <div class="second-menu" v-if="item.key === 'about' && item.showSubMenu">
            <p @click.stop="toPage('/About')">关于广烁</p>
<!--            <p @click.stop="toPage('/ProductQualification')">产品资质</p>-->
            <p @click.stop="toPage('/ContactUs')">联系我们</p>
          </div>
        </span>
      </div>
    </div>
    <div class="line-info" v-if="clientWidth>mobileWidth">
      <img src="../assets/imgs/icon_phone@2x.png">
      <div>
        <p>全国统一服务热线</p>
        <p class="phone-info">0411-82951884</p>
      </div>
    </div>
    <img src="../assets/imgs/mobile/icon_menu@2x.png" class="right-icon" @click="toOpenMobileMenu" v-else>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
    isShowMenu: {
      type: Boolean,
      default: false,
    },
    currentMenu: {
      type: String,
      default() {
        return 'index';
      },
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  data() {
    return {
      // currentMenu: 'index',
      activeSubMenu: null,
      menus: [
        {
          key: 'index',
          title: '首页',
          keyPath: '/',
        },
        {
          key: 'plan',
          title: '国产化方案',
          keyPath: '/LocalizationPlan',
        },
        {
          key: 'eSeal',
          title: '电子印章',
          keyPath: 'http://seal.lngsyz.com',
          isExternal: true,
        },
        {
          key: 'service',
          title: '刻企服务',
          keyPath: 'https://kq.lngsyz.com/login',
          isExternal: true,
        },
        // {
        //   key: 'lnzc',
        //   title: '辽宁政采',
        //   keyPath: '/lnzc',
        // },
        {
          key: 'product',
          title: '产品中心',
          showSubMenu: false,
        },
        // {
        //   key: 'apply',
        //   title: '申请有礼',
        //   keyPath: 'https://kq.lngsyz.com/collect/sealInfo/toMakeSeal',
        //   isExternal: true,
        // },
        {
          key: 'download',
          title: '资源下载',
          keyPath: '/ResourcesDownload',
        },
        {
          key: 'about',
          title: '关于广烁',
          keyPath: '/About',
          showSubMenu: false,
        }],
      links: [
        '/app/广烁电子签章系统(客户端)V2.0.1.19_政企通用互联网版(20240529).msi',
        '/app/广烁电子签章系统(中间件)V2.0.1.14_印章制作企业专用(20231218)_正式版.msi',
        '/app/广烁印章排版系统V1.5.msi',
        '/app/sealclienthelp_v2.0.pdf',
      ],
    };
  },
  mounted() {
    document.addEventListener('click', () => {
      if (this.activeSubMenu) {
        this.activeSubMenu.showSubMenu = false;
      }
    });
  },
  methods: {
    toIndex() {
      this.$router.replace('/');
    },
    toOpenMobileMenu() {
      this.$emit('openMobileMenu');
    },
    // 点击切换菜单
    handleClickMenu(item) {
      if (item.key === 'product' || item.key === 'about') {
        if (this.activeSubMenu) {
          this.activeSubMenu.showSubMenu = false;
        }

        this.activeSubMenu = item;
        this.activeSubMenu.showSubMenu = true;
        return;
      }

      this.showSecondMenu = false;
      if (!item.keyPath) {
        return;
      }
      if (item.key === 'index') {
        this.$router.replace(item.keyPath);
      } else if (item.isExternal) {
        window.open(item.keyPath, '_black');
      } else {
        this.$router.push(item.keyPath);
      }
    },
    toPage(path) {
      this.showSecondMenu = false;
      this.$router.push(path);
    },
    downLoad(index) {
      window.open(this.links[index - 1]);
    },
  },
};
</script>

<style lang="less" scoped>
.common-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 80px;
  position: fixed;
  background-color: #fff;
  top: 0;
  z-index: 99;
  box-shadow: 0 0 10px #ccc;

  .logo-info {
    padding: 4px 0;
    cursor: pointer;
    display: flex;
    flex: 2;
    align-items: center;

    img {
      width: 281px;
      height: 36px;
    }

    .menu-wapper {
      flex: 1;
      display: flex;
      justify-content: space-evenly;

      .menu-item {
        font-size: 18px;
        color: #333;
        border-bottom: none;
        vertical-align: baseline;
        position: relative;

        .second-menu {
          position: absolute;
          top: 40px;
          left: -60%;
          background: #fff;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
          width: 180px;
          padding: 9px 0;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
          border-radius: 4px;
          text-align: center;

          p {
            margin: 0;
            padding-bottom: 18px;

            &:hover {
              color: #F75959;
            }
          }

          & p:last-child {
            padding: 0;
          }

          .cailiao {
            // padding-bottom: 18px;
          }
        }
      }

      .menu-item-active {
        color: #AF0000;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .line-info {
    display: flex;
    cursor: pointer;

    p {
      margin: 0;
    }

    img {
      width: 26px;
      height: 37px;
      margin-right: 12px;
      margin-top: 10px;
    }

    .div {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }

    .phone-info {
      color: #AF0000;
      letter-spacing: 2px;
      margin-top: 5px;
    }
  }

  .right-icon {
    width: 24px;
    height: 18px;
  }
}
</style>
